import { useMemo, useState } from "react";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";

import Select from "../components/Select";
import TextInput from "../components/TextInput";
import Modal from "../components/Modal";

import {ReactComponent as TrashIcon} from '../assets/images/svg/trash.svg'
import DeleteModal from "../components/Delete";
import { addGood, deleteGood, setCurrentGood, updateGood } from "../redux/slices/appInfoSlice";
import { addToast } from "../redux/slices/toastSlice";

const  DirectoryPage = () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.appInfo.currentGood)
    const data = useSelector(state => state.appInfo.goods);
    const user = useSelector(state => state.users.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);



    const columns = useMemo(
        () => [
            {
                Header: "Назва",
                accessor: 'name',
            },
            {
                Header: 'Ціна',
                accessor: 'price'
            },
            {
                Header: 'Дії',
                Cell: ({row}) => {
                    const { id } = row.original;
                    return (
                         <TrashIcon onClick={() => {dispatch(setCurrentGood(id)); setIsModalDeleteOpen(true)}}/>
                    )
                }
            },
        ],
        []
    )


    return (
        <>
        <div className="row  m-0 p-0">
           <p className="h3">Наші продукти</p>
            {user.rigths === '1' &&
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити новий продукт</button>
                    </li>
                </ul>
            }
        </div>
        <div className="row  m-0 p-0">
            <Table rest={{data, columns, search: false, paggination: false, onDoubleClick: user.rigths !== '1' ? () => {} : setIsModalOpen }}/>
        </div>
        <DirectoryModal rest={{isModalOpen, data, closeModal}}/>
        <DeleteModal rest={{itemType: 'directory', action: () => deleteGood({id}), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
    </>
    ) 
    
};

const DirectoryModal = ({rest}) => {
    const dispatch = useDispatch();
    
    const { isModalOpen, closeModal, data = [] } = rest;

    const good = data.find(g => g.id === isModalOpen) || {};

    const { id = null, name = null, price = null} = good;

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Продукт</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ name: name ?? '', price: price ?? ''}}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            if (id) {
                                dispatch(updateGood({good: {id, ...values}}))
                                .then(data => {
                                    const { response} = data.payload
                                    if (response.status === 'ok') {

                                        dispatch(addToast({id: Date.now(), message: response.message, status: response.status}));
                                        closeModal()
                                    } else {
                                        dispatch(addToast({id: Date.now(), message: response.message, status: response.status}));
                                    }
                                    setSubmitting(false);
                                    
                                })
                            } else {
                                dispatch(addGood({good: values}))
                                .then(data => {
                                    const { response } = data.payload
                                    if (response.status === 'ok') {

                                        dispatch(addToast({id: Date.now(), message: response.message, status: response.status}));
                                        closeModal()
                                    } else {
                                        dispatch(addToast({id: Date.now(), message: response.message, status: response.status}));
                                    }
                                    setSubmitting(false);
                                    
                                })
                            }
                            resetForm();
                        }}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="row ">
                                <div className="col-6">
                                    <TextInput 
                                        label='Назва'
                                        id="name"
                                        name='name'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Ціна'
                                        id="price"
                                        name='price'
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary me-2" onClick={closeModal}>Закрити</button>
                                    <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                        {isSubmitting ? "Збереження..." : "Зберегти"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Назва обовязкова!'),
    price: Yup.string()
        .required('Ціна обовязкова!')
})

export default DirectoryPage